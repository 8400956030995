@import url(./assets/fonts/AU_Sans_BETA/stylesheet.css);

// @font-face {
//     font-family: 'AU_SANS_BETA';
//     src: url(./assets/fonts/AU_Sans_BETA/);
// }
*:not(i) {
  font-family: 'au_sans_beta_displaylight' !important;
}

h1 {
  font-size: 2.25rem;

  @media (min-width: 800px) {
    font-size: 3.3125rem;
    line-height: 1.15em;
  }
}

h2 {
  font-size: 1.75rem;
  color: #173f35 !important;

  @media (min-width: 576px) {
    font-size: 2.25rem;
    line-height: 1.5em;
  }
}

h3 {
  color: #173f35 !important;
}

.mt-1 {
  margin-top: 1em;
}

.text-center {
  text-align: center;
}

.p10 {
  padding: 10px !important;
}

.m8 {
  margin: 8px !important;
}

.custom-flex-pill {
  display: flex !important;
  flex: 1 1 20%;
  text-transform: capitalize !important;
}

.btn-custom-green {
  background-color: #173f35 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: inherit !important;
}

.custom-margin-speaker-name {
  margin-bottom: 0px !important;
}

.custom-margin-speaker-title {
  margin-top: 0px !important;
  text-transform: capitalize !important;
}

.custom-image {
  background-position: 0 0 !important;
  width: 100% !important;
  padding-top: 31.25% !important;
  transform: translate3d(0px, 0px, 0px) !important;
  background-size: cover !important;
  height: 0 !important;
  max-height: 0 !important;
  min-height: 0 !important;
}

.custom-image-size {
  object-fit: cover;
  height: 130px !important;
  width: 130px !important;
  object-position: top;
}

.custom-image-size-manual {
  object-fit: contain;
  height: 100%;
  width: auto;
  // height: 130px !important;
  // width: 130px !important;
  // object-position: center;
  aspect-ratio: 1;
  background-color: #2e2e29;
}

.custom-ul-margin-bottom {
  margin-bottom: 0px;
}

.custom-hover:hover {
  background-color: #173f35;
  color: #fff;
}

.custom-home-descr-margin {
  margin-top: 20px !important;
}

.custom-card-media {
  height: 200px;
}

.custom-card-video-prizes {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.custom-card-prizes-media {
  // background-size: contain !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: contain !important;
  height: 150px;
  width: 380px;
}

.custom-grid-video-card-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-card-action-video-prizes {
  margin-top: auto;
}

// .custom-grid-card-padding {
//     padding-left: 110px !important;
//     padding-right: 110px !important;
// }

@media (min-width: 1200px) {
  .custom-grid-card-padding {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
}

@media (max-width: 991px) {
  .custom-card-prizes-media {
    height: 100px;
    width: 280px;
  }
}