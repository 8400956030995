/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 2, 2020 */



@font-face {
    font-family: 'au_sans_beta_displayextrabold';
    src: url('au_sans_beta_display-extrabold-webfont.woff2') format('woff2'),
        url('au_sans_beta_display-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'au_sans_beta_displaybold';
    src: url('au_sans_beta_display-bold-webfont.woff2') format('woff2'),
        url('au_sans_beta_display-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'au_sans_beta_displaylight';
    src: url('au_sans_beta_display-light-webfont.woff2') format('woff2'),
        url('au_sans_beta_display-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'au_sans_beta_display_regulaRg';
    src: url('au_sans_beta_display-regular-webfont.woff2') format('woff2'),
        url('au_sans_beta_display-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'au_sans_beta_displaymedium';
    src: url('au_sans_beta_display-medium-webfont.woff2') format('woff2'),
        url('au_sans_beta_display-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'au_sans_beta_displaythin';
    src: url('au_sans_beta_display-thin-webfont.woff2') format('woff2'),
        url('au_sans_beta_display-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}